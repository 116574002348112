.backimage {
    height: 100%;
    background-image: url('../images/home_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.h-h {
    height: 100vh;
}

.company_logo {
    height: 100px;
}

.unit {
    padding: 0px !important;
}

.link {
    width: 100%;
    text-align: left !important;
}

cr-icon-button#download {
    display: none !important;
}

h4.count {
    font-size: 20px;
    margin: 0;
}

.grid {
    display: grid;
}

.area {
    padding: 10px;
    background: #005284;
    color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
}

.area h4 {
    font-weight: 400;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00528466;
    border-radius: 4px;
}

.unit_assign {
    padding: 7px 10px !important;
    background: #e1ebee;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    font-size: 13px;
}

.bg-green {
    background-color: #4CAF50 !important;
}

.steps {
    display: flex;
}

.step {
    height: 5px;
    background: #d3d3d3;
    margin: 4px;
    border-radius: 10px;
}

.active.step,
.done.step {
    background-color: #005284
}

.step-btn button {
    padding: 8px 16px !important;
    color: #fff;
    text-decoration: none;
    background: #005284;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    width: 100%;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .6px;
    border: 1px solid #005284;
}

.step_back_btn {
    padding: 8px 16px !important;
    color: #005284 !important;
    text-decoration: none !important;
    background: #ffffff !important;
    border-radius: 3px !important;
    outline: none !important;
    cursor: pointer !important;
    width: 30% !important;
    margin: 2px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: .6px !important;
    border: 1px solid #005284;
}

.overclass {
    z-index: 2 !important;
    background-color: rgba(78,78,78,70%) !important;
    -webkit-backdrop-filter: blur(3px) !important;
    backdrop-filter: blur(3px) !important;
}

textarea:focus-visible {
    outline: .75px solid #005284;
}

.index {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #005284;
    /* margin:40px; */
    /* text-align: center; */
}

.step-btn {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(192, 192, 192, 0.74);
    padding: 10px;
}

.step-btn1 {
    display: flex;
}

.step-btn1 button {
    padding: 12px 16px !important;
    color: #fff;
    text-decoration: none;
    background: #005284;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/* .embed iframe {
    width: 100%;
    height: 400px;
} */

.embed {
    display: flex;
}

.marRight-5 {
    margin-right: -5px;
}

.marLeft-5 {
    margin-left: -5px;
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.group {
    position: relative !important;
    opacity: 1 !important;
    pointer-events: unset !important;
}

nav {
    background: #fff !important;
}

.menu {
    width: 250px;
    left: -1px !important;
}

.menu ul {
    padding: 0;
    margin: 0;
}

.menu ul li {
    font-size: 16px;
    transition: all 0.25s ease;
    animation: fadeInRight .25s ease forwards;
}

.menu ul li a {
    color: #fff;
    display: block;
    padding: 10px 20px;
}

.az-color {
    background: #005284 !important;
}

.cam-header {
    background-color: #000;
    display: flex;
    padding: 10px;
}

.cam-footer {
    background-color: #000;
    display: flex;
    padding: 10px;
}

.cam-video {
    display: flex;
}

.cam-header i {
    color: #fff;
}

.letter {
    background: #65a8d2;
    color: #fff;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-panel {
    display: flex;
    align-items: center;
}

.pad_z {
    padding: 0 !important;
}

.trans {
    transition: cubic-bezier(0.86, 0, 0.07, 1);
}

.size-text {
    font-size: 20px;
    margin: 0;
}

.card-content .row {
    margin: 0;
}

.card-content > p {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
}

.form-box {
    box-shadow: 0px 0px 10px 3px #e1e1e1;
    padding: 20px !important;
    margin: 0px 15px !important;
    background: #fff;
}

.horizontal {
    margin: 0;
    margin-bottom: 26px;
    padding: 16px;
    background: #f3f3f3 !important;
}

.p-20 {
    padding: 20px;
    ;
}

.font-p {
    font-size: 30px;
    text-align: right;
    margin: 0;
}

.f-d-c {
    display: flex;
    flex-direction: column;
}

.large {
    width: 100%;
}

.about h2 {
    font-size: 20px;
    text-align: justify;
}

.about {
    text-align: justify;
}

.account p {
    font-size: 16px;
    font-weight: 500;
}

.account h2 {
    font-size: 20px;
    font-weight: 600;
    color: #005284;
}

.flex {
    display: flex;
    width: 100%;
}

.mZero {
    margin: 0;
}

.align-item-center {
    display: flex;
    align-items: center;
}

.justity-center {
    justify-content: center;
}

.align-space-between {
    align-items: center;
    justify-content: space-between;
}

.loading {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.sigCanvas {
    border: 1px solid #d4d4d4;
}

.sound-wave {
    height: 100px;
    width: 300px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #ffffffd1;
}

.flex-end {
    justify-content: flex-end;
}

.padB15 {
    padding-bottom: 15px;
}

.padZero {
    padding: 0 !important;
}

.padLeft15 {
    padding-left: 15px;
}

.padRight15 {
    padding-right: 15px;
}

.mar10 {
    margin: 10px;
}

.collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
}

.SlideIn-appear {
    transform: translateX(30px);
    opacity: 0;
}

.SlideIn-appear.SlideIn-appear-active {
    opacity: 1;
    transform: translateX(0);
    ;
    transition: all 0.6s linear;
}

.SlideIn-enter {
    opacity: 0;
    transform: translateX(30px);
}

.SlideIn-enter.SlideIn-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.2s linear 0.4s;
}

.SlideIn-leave {
    opacity: 1.0;
    transform: translateX(0);
}

.SlideIn-leave.SlideIn-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transform: translateX(-30px);
    transition: all 0.2s linear;
}

.mb-40 {
    margin-bottom: 40px;
}

.bg-f5 {
    background: #f5f5f5;
}

.notify {
    position: relative;
    top: -16px;
    left: 2px;
    font-size: 12px;
    font-weight: bold;
}

button.icon {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    background: #232833;
    height: 50px;
    width: 50px;
    color: #4f5b66;
    font-size: 10pt;
    -webkit-transition: all .55s ease;
    -moz-transition: all .55s ease;
    -ms-transition: all .55s ease;
    -o-transition: all .55s ease;
    transition: all .55s ease;
}

.scroll::-webkit-scrollbar-track:vertical {
    border-left: 1px solid #E7E7E7;
    box-shadow: 1px 0 1px 0 #F6F6F6 inset, -1px 0 1px 0 #F6F6F6 inset;
}

.scroll::-webkit-scrollbar-track:horizontal {
    border-top: 1px solid #E7E7E7;
    box-shadow: 0 1px 1px 0 #F6F6F6 inset, 0 -1px 1px 0 #F6F6F6 inset;
}

.force-show-scrollbars::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: #FAFAFA;
    width: 16px;
}

.scroll::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: #C1C1C1;
    border-color: transparent;
    border-radius: 9px 8px 8px 9px;
    border-style: solid;
    border-width: 3px 3px 3px 4px;
    /* Workaround because margins aren't supported */
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

audio::-internal-media-controls-download-button {
    display: none;
}

audio::-webkit-media-controls-enclosure {
    overflow: hidden;
}

audio::-webkit-media-controls-panel {
    width: calc(100% + 30px);
    /* Adjust as needed */
}

nav.nav.nav-tabs a {
    width: 50%;
    text-align: center;
    padding: 12px 24px;
    font-weight: 600;
}

.toolbox-icon.toggled.security-toolbar-button {
    display: none !important;
}

#folder-grid .item>span {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.15);
    border: 1px solid black;
    opacity: 1;
    filter: alpha(opacity=100);
    color: #888;
    font-weight: 400;
    display: block;
    text-decoration: none;
    border-radius: 6px;
    position: relative;
}

#folder-grid .item>span .item-thumbnail {
    -webkit-transition: all 0.125s ease-in-out;
    transition: all 0.125s ease-in-out;
    display: block;
    height: auto;
    top: 0px;
    bottom: 0px;
    position: absolute;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-color: transparent;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-position: center 100%;
    background-repeat: no-repeat;
}

#folder-grid .item.folder>span {
    top: 0px;
    background-color: #3d9ee9;
}

.btn.btn-dropdown {
    text-align: left;
    padding-right: 44px !important;
    min-width: 0px !important;
}

.dropdown {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

#folder-grid .item .item-meta .item-title {
    display: block;
    font-size: 18px;
    width: auto;
    padding: 16px 25px;
    padding-right: 40px;
    left: 0px;
    bottom: 0px;
    position: relative;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #222;
    text-align: left;
}

#folder-grid .item .item-meta {
    border-top: 2px solid rgba(0, 0, 0, 0.025);
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: #888;
    position: absolute;
    bottom: 40px;
    right: 40px;
    left: 15px;
    width: auto;
}

#folder-grid .item.folder>span:after {
    content: "";
    height: 6px;
    left: 6px;
    position: absolute;
    right: 6px;
    top: -6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: block;
    background-color: #2664b7;
}

#folder-grid .item>span:before {
    content: "";
    display: block;
    padding-top: 100%;
    padding-top: 80%;
}

#folder-grid .item {
    padding-right: 40px;
    padding-bottom: 40px;
    margin-bottom: 20px;
    position: relative;
    /* -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden; */
    cursor: pointer;
}

.create-spaces {
    min-width: 44px;
    height: 44px;
    font-size: 15px;
    background: linear-gradient(90deg, #be1f24, #005284);
    text-decoration: none;
    color: #ffffff;
    border-radius: 100px !important;
}

.create-folder {
    min-width: 44px;
    height: 44px;
    font-size: 15px;
    border: 1px solid #111;
    color: #111;
    background-color: transparent;
    border-radius: 100px !important;
}

.nav-tabs {
    /* border: 1px solid #dee2e6; */
}

.nav-tabs .nav-link.active {
    color: #fff;
    background-color: #005284;
    border: 0px;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
}

.action-top p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.action-top {
    padding: 15px 0;
    background-color: #f5f5f5;
}

.ex_border {
    border-top: 1px solid #d8d7d7;
}


/* .card.read {
    filter: sepia(1);
} */

.card.unread {
    background: #92006717;
}

video::-internal-media-controls-download-button {
    display: none;
}

video::-webkit-media-controls-enclosure {
    overflow: hidden;
}

video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
    /* Adjust as needed */
}

.Blink {
    animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
    position: absolute;
    top: 0px;
    right: -2px;
    font-size: 10px;
}

.submit {
    padding: 5px 11px;
    border-radius: 7px;
    border: 1px solid;
    font-size: 15px;
    font-weight: 600;
}

.msgnoti p img {
    max-width: 100% !important;
}

@keyframes blinker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.scroll::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 20%;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}

@media only screen and (max-width: 500px) {
    .size-text {
        font-size: 15px;
    }
    .topic {
        font-size: 10px;
    }
}

.team-group{

    border-radius: 10px !important;
    
}