.react-slideshow-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.react-slideshow-container .nav {
    z-index: 10
}

.react-slideshow-container .default-nav {
    height: 30px;
    /* background: rgba(255, 255, 255, 0.6); */
    background: transparent;
    width: 30px;
    border: 0;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.svg {
    filter: invert(100%) sepia(99%) saturate(16%) hue-rotate(169deg) brightness(104%) contrast(100%) !important;
}

.react-slideshow-container .default-nav:hover,
.react-slideshow-container .default-nav:focus {
    /* background: #fff; */
    color: #666;
    outline: 0
}

.react-slideshow-container .default-nav.disabled:hover {
    cursor: not-allowed
}

.react-slideshow-container .default-nav:first-of-type {
    margin-right: -30px;
    border-right: 0;
    border-top: 0;
    margin-left: 10px
}

.react-slideshow-container .default-nav:last-of-type {
    margin-left: -30px;
    margin-right: 10px
}

.react-slideshow-container+ul.indicators {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 15px;
    margin-left: -40px;
}

.react-slideshow-container+ul.indicators li {
    display: inline-block;
    position: relative;
    width: 7px;
    height: 7px;
    padding: 5px;
    margin: 0
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator {
    border: 0;
    opacity: .25;
    cursor: pointer;
    background: transparent;
    color: transparent
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    content: '';
    background: #005284;
    text-align: center
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:hover,
.react-slideshow-container+ul.indicators .each-slideshow-indicator.active,
.react-slideshow-container+ul.indicators .each-slideshow-indicator:focus {
    opacity: .75;
    outline: 0
}

.react-slideshow-fade-wrapper {
    width: 100%;
    overflow: hidden
}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap>div {
    position: relative;
    opacity: 0
}

.react-slideshow-wrapper .react-slideshow-fade-images-wrap>div[aria-hidden='true'] {
    display: none
}

.react-slideshow-wrapper.slide {
    width: 100%;
    overflow: hidden
}

.react-slideshow-wrapper .images-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.react-slideshow-wrapper .images-wrap>div[aria-hidden='true'] {
    display: none
}

.react-slideshow-zoom-wrapper {
    width: 100%;
    overflow: hidden
}

.react-slideshow-zoom-wrapper .zoom-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden
}

.react-slideshow-zoom-wrapper .zoom-wrapper>div {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.react-slideshow-zoom-wrapper .zoom-wrapper>div[aria-hidden='true'] {
    display: none
}