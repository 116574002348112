.az-list-link {
    color: #364878 !important;
    font-size: 15px;
    font-weight: 600;
    transition: all 0.5s ease-in-out;
}
.az-list-link:hover {
    background: #d6ebff;
    background-color: #d6ebff;
    border-radius: 10px;
}