.az-hover-bg:hover {
	box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1) !important;
	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}
.az-hover:hover .az-hover-bg:not(:hover) {
    -webkit-filter: grayscale(1) !important;
    filter: grayscale(1) !important;
    opacity: .75 !important;
	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}